import { About, Roadmap, Team, Tokenomics } from '../components/aboutUs';
import { Footer } from '../components/homepage';

export default function AboutUs() {
  return (
    <div className="text-white">
      <div>
        <About />
        <Roadmap />
        <Team />
        <Tokenomics />
        <Footer />
      </div>
    </div>
  );
}
