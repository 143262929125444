import { RouterProvider } from 'react-router-dom';
import Routes from './routes';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';
import { WalletProvider } from './contexts/WalletContext.jsx';
import 'react-toastify/dist/ReactToastify.css';
import NFTs from './components/NFTs.jsx';
import './services/walletConnect.js';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
  },
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <WalletProvider>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <RouterProvider router={Routes()} />
      </WalletProvider>
    </QueryClientProvider>
  );
};

export default App;
