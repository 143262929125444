import React from 'react';
import './style.css';

const teamMembers = [
  {
    title: 'CoFounder',
    name: 'Baldeep Singh Sethi',
    image: '/assets/team/baldeep.jpeg',
  },
  {
    title: 'CoFounder',
    name: 'Shubham Bisht',
    image: '/assets/team/bisht.png',
  },
  {
    title: 'CoFounder',
    name: 'Rishabh Bhatti',
    image: '/assets/team/bhatti.jpeg',
  },
];

const Team = () => {
  // Function to expand the card
  const handleMouseEnter = (e) => {
    const card = e.currentTarget; // Using currentTarget to ensure we get the div itself
    card.classList.add('grow');
  };

  // Function to shrink the card back to its original size
  const handleMouseLeave = (e) => {
    const card = e.currentTarget;
    card.classList.remove('grow');
  };

  return (
    <section className="team-container w-full min-h-screen">
      <div className="2xl:container mx-auto flex flex-col items-center w-full h-full text-white text-center uppercase pt-28 2xl:px-0lg:pb-0 pb-8 sm:px-6 px-3">
        <h1 className="sm:text-6xl text-4xl">team</h1>
        <p className="sm:text-2xl text-xl mt-7">
          Meet our fabulous team who makes dreams come true
        </p>

        <div className="w-full 2xl:lg:gap-x-10 gap-7 sm:gap-x-4 gap-x-2 flex flex-row mt-10 transition-all ease-in-out font-bold md:items-center md:justify-center sm:px-6">
          {teamMembers.map((member, index) => (
            <div
              key={index}
              className={`relative rounded-[45px] ${
                index === 0
                  ? 'xl:w-[380px] sm:w-[450px] w-[320px]'
                  : 'xl:w-[340px] sm:w-[400px] w-[250px]'
              }   h-[480px] transition-all ease-in-out duration-300`}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <img
                src={member.image}
                alt="Team member"
                className="h-full w-full pointer-events-none rounded-[45px] team-img"
              />
              <div className="absolute bottom-10 left-6 text-left">
                <p className="text-xs">{member.title}</p>
                <p className="md:text-xl text-base">{member.name}</p>
                <hr className="h-10 border border-primary absolute top-0 -left-2" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Team;
