import { useState } from 'react';
import { RoadmapCard } from '../form/Button';
import './style.css';
const Roadmap = () => {
  const data = [
    {
      quarter: 'Q4 2023',
      quarterInfo: ['Website', 'Socials SETUP', 'Introduction to  Entrypasses'],
    },
    {
      quarter: 'Q4 2023',
      quarterInfo: ['User Dashboard', 'Whitepaper 1.0 ', 'Tokonomics'],
    },
    {
      quarter: 'Q1-2024',
      quarterInfo: [
        'Dashboard2.0',
        'Mainnet EntryPass Launch',
        'BlazSwap',
        'Website upgradation',
        'Gitbook Setup',
      ],
    },
    {
      quarter: 'Q2-2024 ',
      quarterInfo: [
        'Funding rounds',
        'Token launch',
        'Web3 domain launch ',
        'Mobile beta launch',
        'Launch pools',
      ],
    },
    {
      quarter: 'Q3 -2024',
      quarterInfo: [
        'Mobile app launch',
        'Business App Launch',
        'NFT Aggregator Platform Beta v1 testing',
        'NFT Solution Launch',
      ],
    },
  ];

  const [cardInfo, setCardInfo] = useState([
    {
      quarter: 'Q4 2023',
      quarterInfo: ['Website', 'Socials SETUP', 'Introduction to  Entrypasses'],
    },
  ]);

  const putQuarter = (itemNo) => {
    setCardInfo([data[itemNo - 1]]);
  };

  return (
    <div className="roadmap-wrapper relative  border-b-2 border-primary">
      <div className="roadmap-text-container">
        <h1>Roadmap</h1>
        <img
          src="/assets/arrowr.png"
          alt=""
          className="lg:h-fit lg:w-fit h-[24px]"
        />
      </div>
      <div className=" lg:hidden card absolute sm:right-[40px] right-1/2 transform sm:translate-x-[0%] translate-x-[50%] z-30">
        <RoadmapCard className="h-[320px] w-[240px]" />
        {cardInfo.map((info) => {
          return (
            <div className="absolute top-1/2 left-1/2 transform translate-x-[-50%] translate-y-[-70%] w-[180px] border-r-2 border-l-2 border-secondary text-white px-2">
              <h1 className="text-xl font-bold mb-3 hover:text-seText hover:text-2xl transition duration-[1s] ease-in-out">
                {info.quarter}
              </h1>
              <ul className="text-xs px-4 ">
                {info.quarterInfo.map((list) => {
                  return <li>{list}</li>;
                })}
              </ul>
            </div>
          );
        })}
      </div>
      <section>
        <div className="earth-roadmap"></div>
        <div className="dash-line">
          {[...Array(5)].map((item, idx) => {
            return <div className="dot cursor-pointer z-[99999]"></div>;
          })}
        </div>
        <div className="info-container">
          {data.map((item) => {
            return (
              <div className="lg:block hidden info h-[100px] 2xl:w-[200px] w-[160px]  absolute border-l border-r border-secondary text-white">
                <h1 className="text-xl hover:text-2xl ease duration-300 font-bold pl-5 pr-3">
                  {item.quarter}
                </h1>
                <ul className="text-[10px] pl-4 list-disc list-inside">
                  {item.quarterInfo.map((info) => {
                    return <li>{info}</li>;
                  })}
                </ul>
              </div>
            );
          })}
        </div>
        <div className="info-container-card absolute bottom-0 lg:hidden block h-[120vw] w-[120vw] transform translate-x-[-40%] translate-y-[50%] rounded-full">
          <h1
            className="cursor-pointer text-white sm:text-xl text-base font-bold  absolute  sm:-top-[5%] -top-[8%] sm:right-[40%] right-[38%] active:text-seText"
            onClick={() => putQuarter(1)}
          >
            Q4 2023
          </h1>
          <h1
            className="cursor-pointer text-white sm:text-xl text-base font-bold  absolute  sm:-top-2 -top-6 sm:right-[25%] right-[18%] active:text-seText"
            onClick={() => putQuarter(2)}
          >
            Q4 2023
          </h1>
          <h1
            className="cursor-pointer text-white sm:text-xl text-base font-bold  absolute  sm:top-16 top-8 sm:right-[12%] right-[4%] active:text-seText"
            onClick={() => putQuarter(3)}
          >
            Q1 2024
          </h1>
          <h1
            className="cursor-pointer text-white sm:text-xl text-base font-bold  absolute  sm:top-[20%] top-[25%]  sm:right-[2%] -right-[12%] active:text-seText"
            onClick={() => putQuarter(4)}
          >
            Q2 2024
          </h1>
          <h1
            className="cursor-pointer text-white sm:text-xl text-base font-bold  absolute  sm:top-[32%] top-[40%] sm:-right-[5%] -right-[12%] active:text-seText"
            onClick={() => putQuarter(5)}
          >
            Q3 2024
          </h1>
        </div>
        <div className="earth-line"></div>
      </section>
    </div>
  );
};

export default Roadmap;
