import { useEffect, useState, ReactElement } from 'react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid';
import Logo from '../../components/Logo';
import { Button } from '../../components/form';
import { useContext } from 'react';
import { useWallet } from '../../hooks/useWallet';
import { useDisconnect, useWeb3Modal } from '@web3modal/ethers5/react';

import { useLocation, useNavigate, NavigateFunction } from 'react-router-dom';

const listItems = [
  {
    label: 'Entrypass',
    action: () => '/entry-pass',
  },
  {
    label: 'About Us',
    action: () => '/about-us',
  },
  {
    label: 'Roadmap',
    action: () => '/roadmap',
  },
  {
    label: 'Blog',
    action: () => 'https://medium.com/@blazpay',
  },
  {
    label: 'Docs',
    action: () => 'https://docs.blazpay.com',
  },
];

function NavList({ navigate }) {
  const handleClick = (action) => {
    if (typeof action === 'function') {
      const result = action();
      if (typeof result === 'string') {
        if (result.startsWith('http')) {
          window.open(result, '_blank');
        } else {
          navigate(result);
        }
      }
    } else {
      console.error('Invalid action type');
    }
  };

  return (
    <ul className="flex flex-col gap-12 lg:flex-row lg:items-center text-base">
      {listItems.map(({ label, action }, key) => (
        <li
          key={label}
          onClick={() => action && handleClick(action)}
          className="font-medium cursor-pointer ease-linear duration-300 hover:-translate-y-[2px] hover:text-prText hover:scale-110"
        >
          {label}
        </li>
      ))}
    </ul>
  );
}

function HamburgerMenu({ navigate, onClose }) {
  const handleClick = (action) => {
    if (typeof action === 'function') {
      const result = action();
      if (typeof result === 'string') {
        if (result.startsWith('http')) {
          window.open(result, '_blank');
          onClose();
        } else {
          navigate(result);
          onClose();
        }
      }
    } else {
      console.error('Invalid action type');
    }
  };

  const onLoginClick = async () => {
    window.open('https://rewards.blazpay.com', '_blank');
  };

  return (
    <ul className="container  mx-auto  z-50 fixed top-24 h-[500px] sm:w-full w-[95vw] flex flex-col items-center justify-center bg-black border border-primary rounded-bl-xl rounded-br-xl">
      {listItems.map(({ label, action }, key) => (
        <li
          key={label}
          onClick={() => action && handleClick(action)}
          className="text-white  font-bold text-xl uppercase w-full text-center  py-6 cursor-pointer hover:bg-[#050505] hover:text-prText"
        >
          {label}
        </li>
      ))}
      <li key="dash">
      <Button
                onClick={onLoginClick}
                variant="contained"
                className="w-40"
                >
                Dashboard
              </Button>
      </li>
    </ul>
  );
}

export function HomeNav() {
  const { isConnected, address } = useWallet();
  const { open } = useWeb3Modal();

  const { disconnect } = useDisconnect();
  const navigate = useNavigate();
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen(!isOpen);
  const closeMenu = () => setIsOpen(false);

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => window.innerWidth >= 960 && toggleMenu
    );
  }, []);

  const onLoginClick = async () => {
    window.open('https://rewards.blazpay.com', '_blank');
  };

  return (
    <nav className="flex justify-center w-full">
      <div className="container mx-auto rounded-full py-0 pr-4 pl-0 border  border-primary bg-[#080808] z-50 shadow-md  fixed top-5 sm:w-full w-[95vw]">
        <div className="flex items-center justify-between">
          <div className="sm:ml-0 -ml-5  sm:scale-100 scale-75">
            <Logo />
          </div>

          <div className="hidden lg:block">
            <NavList navigate={navigate} />
          </div>

          <div className="flex gap-5 items-center">
              {isConnected ? (
                <Button variant="outlined" onClick={() => disconnect()}>
                  Disconnect
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={() => open({ view: 'Connect' })}
                >
                Connect Wallet
                </Button>
              )}
            <div>

              <Button
                onClick={onLoginClick}
                variant="contained"
                className="w-40 hidden md:block"
                >
                Dashboard
              </Button>
                </div>

            <div>
              <Button
                variant="outlined"
                color="blue-gray"
                onClick={toggleMenu}
                className="-ml-2 mr-2 lg:hidden"
              >
                {isOpen ? (
                  <XMarkIcon
                    onClick={toggleMenu}
                    className="sm:h-8 sm:w-8 h-6 w-6 stroke-2 "
                  />
                ) : (
                  <Bars3Icon className="sm:h-8 sm:w-8 h-6 w-6 stroke-2" />
                )}
              </Button>
            </div>
          </div>
        </div>
        {isOpen ? (
          <HamburgerMenu navigate={navigate} onClose={closeMenu} />
        ) : (
          <></>
        )}
      </div>
    </nav>
  );
}
