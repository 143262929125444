import React from 'react';

function Privacy() {
  return (
    <div className='max-w-4xl mx-auto px-4 py-16 text-white'>
      <h1 className='text-3xl font-bold mb-4'>
        BlazPay Labs Corp. Global Privacy Notice
      </h1>
      <p className='mb-2'>
        <strong>Date of Last Revision:</strong> 12 August 2024
      </p>
      <p className='mb-4'>
        <strong>Effective:</strong> 12 August 2024
      </p>

      <p className='mb-4'>
        BlazPay Labs Corp. ("BlazPay," "we," "us," or "our") is a leading
        financial technology company that provides innovative blockchain
        solutions, including the BlazPay extension, swap, bridge, and liquidity
        services. We are committed to respecting your privacy and safeguarding
        your personal information in accordance with applicable laws and
        regulations.
      </p>

      <p className='mb-4'>
        This Privacy Notice applies to our website,{' '}
        <a
          href='https://blazpay.com'
          className='text-blue-400 hover:text-blue-300'
        >
          https://blazpay.com
        </a>
        , and all BlazPay products and services, including the BlazPay
        extension, swap, bridge, and liquidity solutions (collectively,
        "Services") where this Privacy Notice is posted, linked, or referenced.
        This Privacy Notice describes how we process the personal information
        collected when you access or use the Services. When you leave the
        Services, this Privacy Notice no longer applies. Any subsequent website,
        application, or service you access is subject to its own terms of
        service and privacy notice (if any) and not this Privacy Notice.
      </p>

      <p className='mb-4'>
        Please read this Privacy Notice carefully. If you do not agree with this
        Privacy Notice or any part thereof, you should not access or use any
        part of the Services. If you change your mind in the future, you must
        stop using the Services. You may exercise your rights in relation to
        your personal information as set out in this Privacy Notice and you may
        contact us at any time regarding those rights using the methods
        described in the "Contact Us" section below.
      </p>

      <h2 className='text-2xl font-semibold mt-6 mb-3'>BlazPay Privacy</h2>
      <p className='mb-2'>BlazPay users - what does this mean for you?</p>
      <ul className='list-disc pl-5 mb-4'>
        <li>
          We prioritize your privacy and the security of your personal
          information.
        </li>
        <li>
          We have designed BlazPay to give you control over your data and to
          provide a transparent overview of how we handle your information.
        </li>
        <li>Do not collect your private keys.</li>
        <li>Do not sell your personal information.</li>
        <li>
          Do not collect or retain personal information unless necessary to
          provide you with the Services and a great user experience.
        </li>
        <li>
          May temporarily process your IP address to provide optimal performance
          and prevent security threats, such as DDoS attacks.
        </li>
        <li>
          Do not collect financial payment or banking information directly;
          however, third-party services may require you to submit such
          information for certain transactions.
        </li>
      </ul>

      <h2 className='text-2xl font-semibold mt-6 mb-3'>
        1. Our Relationship With You
      </h2>
      <p className='mb-4'>
        BlazPay Labs Corp. provides the Services and determines the purpose and
        means of processing your personal information. You are subject to the
        Terms of Use of the Services, which describe the contractual
        relationship between you and BlazPay. The Services can be used by
        organizations or individual users. If you’re using the Services on
        behalf of an organization, your organization owns the associated
        accounts and may take certain actions in respect to your access or
        abilities to use the Services.
      </p>

      <h2 className='text-2xl font-semibold mt-6 mb-3'>
        2. Personal Information We Collect
      </h2>
      <p className='mb-4'>
        We collect personal information about you as described in this section.
        Some information is provided by you, while other information is
        collected automatically. All information we collect is subject to the
        use limitations described in section 3.
      </p>

      <h3 className='text-xl font-semibold mt-4 mb-2'>
        Personal Information You Provide to Us
      </h3>
      <ul className='list-disc pl-5 mb-4'>
        <li>
          <strong>Account and Profile Information:</strong> When creating an
          account or profile, you may provide your name, email address, and
          other necessary details.
        </li>
        <li>
          <strong>Contact Information:</strong> You can provide additional
          information during interactions with us, such as through emails or
          other communications.
        </li>
        <li>
          <strong>Support Information:</strong> When requesting support, we may
          collect personal information necessary to address your queries.
        </li>
        <li>
          <strong>Financial Information:</strong> While we do not collect
          payment information directly, third-party services may collect this
          information for processing transactions.
        </li>
      </ul>

      <h3 className='text-xl font-semibold mt-4 mb-2'>
        Information Collected Automatically
      </h3>
      <ul className='list-disc pl-5 mb-4'>
        <li>
          <strong>Device and Usage Information:</strong> We may collect data
          such as IP addresses, device identifiers, and interactions with the
          Services to enhance user experience and security.
        </li>
        <li>
          <strong>Cookies and Similar Technologies:</strong> We use cookies and
          similar technologies to collect information automatically, which helps
          improve the functionality and performance of the Services.
        </li>
      </ul>

      <h2 className='text-2xl font-semibold mt-6 mb-3'>
        3. How We Use the Personal Information We Collect
      </h2>
      <p className='mb-4'>
        We use the personal information we receive or collect for the following
        purposes:
      </p>
      <ul className='list-disc pl-5 mb-4'>
        <li>
          <strong>To Provide Our Services:</strong> Your information is
          necessary for the basic functionality of the Services and to address
          your requests.
        </li>
        <li>
          <strong>For Personalization:</strong> To customize your experience
          based on your preferences and settings.
        </li>
        <li>
          <strong>For Product Improvement:</strong> To develop and enhance our
          Services and features.
        </li>
        <li>
          <strong>To Communicate with You:</strong> To provide customer support,
          send updates, and respond to inquiries.
        </li>
        <li>
          <strong>For Marketing Purposes:</strong> Subject to consent, to send
          promotional materials and updates.
        </li>
        <li>
          <strong>For Security and Compliance:</strong> To protect the integrity
          of our Services and comply with legal obligations.
        </li>
      </ul>

      <h2 className='text-2xl font-semibold mt-6 mb-3'>
        4. Who We Share Personal Information With
      </h2>
      <p className='mb-4'>
        We may disclose personal information under the following circumstances:
      </p>
      <ul className='list-disc pl-5 mb-4'>
        <li>
          <strong>Service Providers:</strong> We work with third parties to
          support our Services, such as hosting and network management.
        </li>
        <li>
          <strong>Legal Requirements:</strong> We may disclose information to
          comply with legal obligations or to protect the rights and safety of
          BlazPay and our users.
        </li>
        <li>
          <strong>Business Partners:</strong> With your consent, we may share
          information with partners for marketing purposes.
        </li>
      </ul>

      <h2 className='text-2xl font-semibold mt-6 mb-3'>5. Your Rights</h2>
      <p className='mb-4'>
        You have certain rights regarding your personal information, including
        the right to access, correct, delete, or restrict processing. To
        exercise these rights, please contact us as described in the "Contact
        Us" section below.
      </p>

      <h2 className='text-2xl font-semibold mt-6 mb-3'>
        6. Retention of Information
      </h2>
      <p className='mb-4'>
        We retain your personal information for as long as necessary to fulfill
        the purposes outlined in this Privacy Notice or as required by law.
      </p>

      <h2 className='text-2xl font-semibold mt-6 mb-3'>
        7. Security and Storage
      </h2>
      <p className='mb-4'>
        We implement reasonable security measures to protect your personal
        information. However, no internet transmission is fully secure. If you
        believe your privacy has been breached, please contact us immediately.
      </p>

      <h2 className='text-2xl font-semibold mt-6 mb-3'>
        8. Data Analytics & Advertising
      </h2>
      <p className='mb-4'>
        We may use data analytics to improve our Services and, subject to
        consent, engage in marketing activities. We work with third-party
        analytics providers to better understand user interactions and enhance
        our offerings.
      </p>

      <h2 className='text-2xl font-semibold mt-6 mb-3'>
        9. Third Party Links and Websites
      </h2>
      <p className='mb-4'>
        Our Services may contain links to third-party websites. This Privacy
        Notice does not apply to those websites. We encourage you to review the
        privacy practices of any third-party sites you visit.
      </p>

      <h2 className='text-2xl font-semibold mt-6 mb-3'>
        10. Changes to Our Privacy Notice
      </h2>
      <p className='mb-4'>
        We may update this Privacy Notice from time to time to reflect changes
        in our practices or legal obligations. We will notify you of any
        significant changes and encourage you to review the Privacy Notice
        periodically.
      </p>

      <h2 className='text-2xl font-semibold mt-6 mb-3'>11. Contact Us</h2>
      <p className='mb-4'>
        If you have any questions or concerns about this Privacy Notice or our
        privacy practices, please contact us at:{' '}
        <a href='mailto:contact@blazpay.com'>contact@blazpay.com</a>.
      </p>
    </div>
  );
}

export default Privacy;
