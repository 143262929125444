import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "./style.css";
import { NextArrow, PrevArrow } from "../form/Button";

export default function Discover() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    autoPlay: true,
    speed: 500,
    autoplaySpeed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: isMobile ? <></> : <PrevArrow className="d-right" />,
    prevArrow: isMobile ? <></> : <NextArrow className="d-left" />,
  };
  return (
    <section className="text-white">
      {/* Super App */}
      <section className="pt-12 discover-bg1">
        <h1 className="lg:text-5xl text-2xl font-bold text-center mb-6 uppercase">
          Discover the Ecosystem
        </h1>
        <div className=" max-w-screen-xl mx-auto ">
          <div className="container min-h-screen ">
            <div className="">
              <h2 className="lg:text-3xl text-xl font-semibold my-5 text-center uppercase">
                Super App
              </h2>

              <Slider {...settings}>
                <div>
                  <div className="discover-container">
                    <div className="dis-left bg-element border border-primary rounded-xl relative">
                      <img src="/assets/superapp/dex.svg" alt="" />
                      <div className="text-center absolute lg:bottom-5 bottom-2 p-2 w-full">
                        <p className="lg:text-xl font-semibold">DEX</p>
                        <p className="lg:text-sm text-xs">
                          Trade crypto instantly across multiple chains
                        </p>
                      </div>
                    </div>
                    <div className="top-left bg-element border border-primary rounded-xl relative">
                      <img src="/assets/superapp/wallet.svg" alt="" />
                      <div className="text-center absolute lg:bottom-5 bottom-2 p-2 w-full">
                        <p className="lg:text-xl font-semibold">Wallet</p>
                        <p className="lg:text-sm text-xs">
                          Fund liquidity pools, earn trading fees
                        </p>
                      </div>
                    </div>
                    <div className="top-right bg-element border border-primary rounded-xl relative">
                      <img src="/assets/superapp/in_app.svg" alt="" />
                      <div className="text-center absolute lg:bottom-5 bottom-2 p-2 w-full">
                        <p className="lg:text-xl text-sm font-semibold">
                          IN APP BROWER
                        </p>
                        <p className="lg:text-sm text-xs">
                          Fund liquidity pools, earn trading fees
                        </p>
                      </div>
                    </div>
                    <div className="bottom bg-element border border-primary rounded-xl relative">
                      <img src="/assets/superapp/abstraction.svg" alt="" />

                      <div className="text-center absolute lg:bottom-5 bottom-2 p-2 w-full">
                        <p className="lg:text-xl text-sm  font-semibold">
                          ACCOUNT ABSTRACTION
                        </p>
                        <p className="lg:text-sm text-xs">
                          Seamlessly transfer assets across chains
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* c */}
                <div>
                  <div className="discover-container-2">
                    <div className="left-top border border-primary rounded-xl relative">
                      <img src="/assets/superapp/username.svg" alt="" />
                      <div className="text-center absolute lg:bottom-5 bottom-2 p-2">
                        <p className="lg:text-xl text-sm  font-semibold"></p>
                        <p className="lg:text-sm text-xs"></p>
                      </div>
                    </div>
                    <div className="right-top bg-element border border-primary rounded-xl relative">
                      <img src="/assets/superapp/chat_pay.svg" alt="" />
                      <div className="text-center absolute lg:bottom-5 bottom-2 p-2 w-full">
                        <p className="lg:text-xl text-sm  font-semibold">
                          CHAT AND PAY
                        </p>
                        <p className="lg:text-sm text-xs">
                          Trade crypto instantly across multiple chains
                        </p>
                      </div>
                    </div>
                    <div className="bottom bg-element border border-primary rounded-xl relative">
                      <img src="/assets/superapp/off_ramp.svg" alt="" />
                      <div className="">
                        <p className="lg:text-xl text-sm  font-semibold">
                          ON-RAMP/OFF-RAMP
                        </p>
                        <p className="lg:text-sm text-xs">
                          Trade crypto instantly across multiple chains
                        </p>
                      </div>
                    </div>
                    <div className="bill-right bg-element border border-primary rounded-xl relative">
                      <img src="/assets/superapp/easy_bills.svg" alt="" />
                      <div className="text-center absolute lg:bottom-5 bottom-2 p-2 w-full">
                        <p className="lg:text-xl text-sm  font-semibold">
                          EASY BILL PAYMENTS
                        </p>
                        <p className="lg:text-sm text-xs">
                          Seamlessly transfer assets across chains
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>

      {/* Business */}
      <section className="discover-business-bg py-5">
        <h2 className="lg:text-3xl text-xl font-semibold my-5 text-center uppercase">
          Business App
        </h2>
        <div className=" max-w-screen-xl mx-auto ">
          <div className="container min-h-screen ">
            <Slider {...settings}>
              <div>
                <div className="business-container">
                  <div className="business-left bg-element border bg-element border-primary rounded-xl relative">
                    <img src="/assets/business/merchant.svg" alt="" />
                    <div className="text-center absolute bottom-5 p-2 w-full">
                      <p className="lg:text-xl text-sm font-semibold">
                        MERCHANT API
                      </p>
                      <p className="lg:text-sm text-xs">
                        Trade crypto instantly across multiple chains
                      </p>
                    </div>
                  </div>
                  <div className="business-top-left border bg-element border-primary rounded-xl relative">
                    <img src="/assets/business/passbool.svg" alt="" />
                    <div className="text-center absolute lg:bottom-5 bottom-2 p-2 w-full">
                      <p className="lg:text-xl text-sm font-semibold">
                        PASSBOOK
                      </p>
                      <p className="lg:text-sm text-xs">
                        Fund liquidity pools, earn trading fees
                      </p>
                    </div>
                  </div>
                  <div className="business-top-right bg-element border border-primary rounded-xl relative">
                    <img src="/assets/business/off-ramp.svg" alt="" />
                    <div className="text-center absolute lg:bottom-5 bottom-2 p-2 w-full">
                      <p className="lg:text-xl text-sm font-semibold">
                        OFF-RAMP
                      </p>
                      <p className="lg:text-sm text-xs">
                        Fund liquidity pools, earn trading fees
                      </p>
                    </div>
                  </div>
                  <div className="business-bottom bg-element border border-primary rounded-xl flex items-center pl-5 ">
                    <img src="/assets/business/gateway.svg" alt="" />
                    <div className="lg:bottom-5 bottom-2 p-2 w-full">
                      <p className="lg:text-xl text-sm font-semibold">
                        PAYMENT GATEWAY
                      </p>
                      <p className="lg:text-sm text-xs">
                        Seamlessly transfer assets across chains
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* c */}
              <div>
                <div className="business-container-2">
                  <div className="business-2-left-top border border-primary rounded-xl relative">
                    <img src="/assets/business/settlement.svg" alt="" />
                    <div className="text-center absolute bottom-5 p-2">
                      <p className="lg:text-xl text-sm font-semibold"></p>
                      <p className="lg:text-sm text-xs"></p>
                    </div>
                  </div>
                  <div className="business-2-right-top border border-primary rounded-xl relative">
                    <img src="/assets/business/invoice.svg" alt="" />
                    <div className="text-center absolute lg:bottom-5 bottom-2 p-2 w-full">
                      <p className="lg:text-xl text-sm font-semibold">
                        CHAT AND PAY
                      </p>
                      <p className="lg:text-sm text-xs">
                        Trade crypto instantly across multiple chains
                      </p>
                    </div>
                  </div>
                  <div className="business-2-bottom border border-primary rounded-xl">
                    <img src="/assets/business/sale.svg" alt="" />
                    <div className=" p-2">
                      <p className="lg:text-xl text-sm font-semibold">
                        ON-RAMP/OFF-RAMP
                      </p>
                      <p className="lg:text-sm text-xs">
                        Trade crypto instantly across multiple chains
                      </p>
                    </div>
                  </div>
                  <div className="business-2-bill-right border border-primary rounded-xl relative">
                    <img src="/assets/business/chain.svg" alt="" />
                    <div className="text-center absolute bottom-5 p-2 w-full">
                      <p className="lg:text-xl text-sm font-semibold">
                        EASY BILL PAYMENTS
                      </p>
                      <p className="lg:text-sm text-xs">
                        Seamlessly transfer assets across chains
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>

      {/* Payment Solution */}
      <section className="discover-payment-bg py-5">
        <h2 className="lg:text-3xl text-xl font-semibold my-5 mb-10 uppercase text-center">
          Payment Solution / Merchant Api
        </h2>
        <div className=" max-w-screen-xl mx-auto ">
          {" "}
          <div className="container min-h-screen payment-main-container ">
            <div>
              <div>
                <div className="payment-container">
                  <div className="payment-left bg-element border border-primary rounded-xl relative  overflow-hidden">
                    <img src="/assets/payment/integration.svg" alt="" />
                    <div className="text-center absolute lg:bottom-5 bottom-2 p-2 w-full">
                      <p className="lg:text-xl text-sm font-semibold uppercase">
                        Effortless Code-Free <br /> Integration
                      </p>
                      <p className="lg:text-sm text-[10px]">
                        Seamlessly embed our solution into your <br /> business
                        checkout, no coding required
                      </p>
                    </div>
                  </div>

                  <div className="payment-top-left bg-element border border-primary rounded-xl relative  overflow-hidden">
                    <img src="/assets/payment/pricing.svg" alt="" />
                    <div className="text-center absolute lg:bottom-5 bottom-0  p-2 w-full">
                      <p className="lg:text-xl text-xs font-semibold uppercase">
                        Optimal Pricing <br /> Through <br /> Gateway Comparison
                      </p>
                      <p className="lg:text-sm text-[10px]">
                        Receive the best rates by comparing quotations <br />{" "}
                        from multiple gateways.
                      </p>
                    </div>
                  </div>
                  <div className="payment-top-right bg-element border border-primary rounded-xl relative overflow-hidden">
                    <img src="/assets/payment/user.svg" alt="" />
                    <div className="text-center absolute lg:bottom-5 bottom-0 p-2 w-full">
                      <p className="lg:text-xl text-xs font-semibold uppercase">
                        Streamlined and Intuitive <br /> User Experience
                      </p>
                      <p className="lg:text-sm text-[10px]">
                        Offer your customers a straightforward and <br /> smooth
                        checkout process.
                      </p>
                    </div>
                  </div>

                  <div className="payment-bottom bg-element border border-primary rounded-xl flex items-center pl-5 overflow-hidden">
                    <div className="lg:bottom-5 bottom-2 p-2 w-full">
                      <p className="lg:text-xl text-xs font-semibold uppercase">
                        QR Code Payments with <br /> Multi-Currency <br />{" "}
                        Support
                      </p>
                      <p className="lg:text-sm text-[10px]">
                        Seamlessly transfer assets across chains
                      </p>
                    </div>

                    <img src="/assets/payment/scan.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}

<div>
  <div>
    <h2 className="text-2xl my-5">Super App</h2>

    <div className="flex gap-5">
      <div className="flex-[.7]">
        <div className="flex gap-5 mb-5">
          <div className="flex-1  border border-primary p-5 rounded-xl flex flex-col items-center justify-center">
            <img src="/assets/userame.png" alt="username" />
            <p>p Username</p>
            <p>Trade crypto instantly across multiple chains</p>
          </div>

          <div className="flex-1 flex flex-col justify-end items-center w-full p-5 text-center border border-primary bg-gradient-to-b from-black to-primary rounded-xl">
            <img
              src="/assets/chatandpay.png"
              alt="username"
              className="w-120 h-[11rem]"
            />
            <div className="b-5 w-full">
              <p>Chat and pay</p>
              <p>Trade crypto instantly across multiple chains</p>
            </div>
          </div>
        </div>
        <div className="flex  border border-primary rounded-xl">
          <img src="/assets/buy-sell.png" alt="" />
          <div className="p-5">
            <p>on-ramp/off-ramp</p>
            <p>Trade crypto instantly across multiple chains</p>
          </div>
        </div>
      </div>
      <div className="flex-[.3]  border border-primary rounded-xl flex flex-col justify-center">
        <img src="/assets/easybillpayments.png" alt="" />
        <div className="text-center">
          <p className="text-xl">Easy Bill Payment</p>
          <p>Trade crypto instantly across multiple chains</p>
        </div>
      </div>
    </div>
  </div>

  <div>
    <h2 className="text-2xl my-5">Business</h2>

    <div className="flex gap-5">
      <div className="flex-[.3]  border border-primary flex flex-col justify-center rounded-xl">
        <img src="/assets/merchant-api.png" alt="" />
        <div className="text-center">
          <p className="text-xl">MERCHANT API</p>
          <p>
            Trade crypto instantly <br />
            across multiple chains
          </p>
        </div>
      </div>
      <div className="flex-[.7]">
        <div className="flex gap-5 mb-5">
          <div className="flex-1  border border-primary rounded-xl flex flex-col items-center justify-center">
            <img src="/assets/pasbok.png" className="h-56" alt="username" />
            <div className="text-center mb-5">
              <p className="text-xl">PASSBOOK</p>
              <p>Fund liquidity pools, earn trading fees</p>
            </div>
          </div>

          <div className="flex-1 flex flex-col justify-center items-center  w-full text-center border border-primary bg-gradient-to-b from-black to-primary rounded-xl">
            <img src="/assets/off-ramp.png" className="w-64" alt="username" />
            <div className=" w-full">
              <p className="text-xl">OFF RAMP</p>
              <p>Fund liquidity pools, earn trading fees</p>
            </div>
          </div>
        </div>
        <div className="border flex items-center border-primary text-center bg-gradient-to-b from-black to-primary rounded-xl">
          <div className="p-5">
            <p className="text-xl">PAYMENT GATEWAY</p>
            <p className="w-max">Seamlessly transfer assets across chains</p>
          </div>
          <img src="/assets/getway.png" className="w-full h-[22rem]" alt="" />
        </div>
      </div>
    </div>
  </div>

  <div>
    <h2 className="text-2xl my-5">Business</h2>

    <div className="flex gap-5">
      <div className="flex-[.7]">
        <div className="flex gap-5 mb-5">
          <div className="flex-1  border border-primary p-5 rounded-xl flex flex-col items-center justify-center">
            <img src="/assets/dailyweeklysettlement.png" alt="username" />
            <p className="text-xl">Daily Weekly Settlement</p>
            <p className="">Trade crypto instantly across multiple chains</p>
          </div>

          <div className="flex-1 flex flex-col justify-end items-center w-full p-5 text-center border border-primary bg-gradient-to-b from-black to-primary rounded-xl">
            <img
              src="/assets/paymentinvoice.png"
              alt="username"
              className="w-120 h-[11rem]"
            />
            <div className="b-5 w-full">
              <p className="text-xl">Payment Invoice</p>
              <p>Trade crypto instantly across multiple chains</p>
            </div>
          </div>
        </div>
        <div className="flex  border border-primary rounded-xl">
          <img src="/assets/salechart.png" alt="" />
          <div className="p-5">
            <p className="text-xl mt-12">Sale Chart</p>
            <p className="mt-4 ml-5">
              Trade crypto instantly across multiple chains
            </p>
          </div>
        </div>
      </div>
      <div className="flex-[.3]  border border-primary rounded-xl flex flex-col justify-center">
        <img src="/assets/defaultchainselection.png" alt="" />
        <div className="text-center">
          <p className="text-xl">Default Chain Selection</p>
          <p>Trade crypto instantly across multiple chains</p>
        </div>
      </div>
    </div>
  </div>
</div>;
