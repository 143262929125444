import { Button } from "../form";
import "./style.css";
import Slider from "react-slick";

const data = [
  {
    title: "Total User",
    count: `401,660+`,
    description: "Active Dashbaord Users",
  },
  {
    title: "Total Transactions",
    count: `2,500,000+`,
    description: "In The Last Few Months",
  },
  {
    title: "Total NFTs Minted",
    count: `1,000,000+`,
    description: "In The Last Few Months",
  },
];

const chains = [
  { title: "BITGERT", icon: "/assets/summary/bitgertSmall.png" },
  { title: "TAIKO", icon: "/assets/summary/taiko.png" },
  { title: "ZKFAIR", icon: "/assets/summary/zkfair.png" },
  { title: "POLYGON", icon: "/assets/summary/polygon.png" },
  { title: "SHARDEUM", icon: "/assets/summary/shardeum.png" },
  { title: "ZETA", icon: "/assets/summary/zeta.png" },
  { title: "ARBITRUM GOERLI", icon: "/assets/summary/arbitrum.png" },
  { title: "HOLESKY", icon: "/assets/summary/polygon.png" },
  { title: "ETH", icon: "/assets/summary/eth.png" },
];

export default function Summary() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 2000,
    cssEase: "ease-in-out",
    arrows: false,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="min-h-screen w-full relative summary  overflow-hidden">
      <div className="animated-circles-c1 absolute rounded-full">
        <div className="circle"></div>
      </div>
      <div className="animated-circles-c2 absolute rounded-full">
        <div className="circle"></div>
      </div>
      <div className="container mx-auto text-white flex flex-col items-center 2xl:pt-64 xl:pt-48 lg:pt-40 pt-32">
        <div className="w-full 2xl:text-4xl lg:text-3xl text-2xl font-bold text-center lg:px-0 px-3">
          <h1>Shaping the Future of Decentralized Trading:</h1>
          <h1>PancakeSwap’s Unstoppable Expansion</h1>
        </div>
        <div className="flex md:flex-row flex-col items-center justify-between 2xl:mt-24 md:mt-16 mt-6 2xl:w-[60%] xl:w-[80%] xl:px-0 w-full">
          {data.map((item) => {
            return (
              <div className="flex flex-col items-center md:mt-0 mt-8">
                <p className="lg:text-2xl text-xl">{item.title}</p>
                <h1 className="lg:text-4xl text-3xl text-[#ff7a1e] font-bold">
                  {item.count}
                </h1>
                <p className="text-base">{item.description}</p>
              </div>
            );
          })}
        </div>
        <div className="s-slider h-fit lg:w-[98vw] w-[90%] lg:mt-20 mt-12 lg:mb-0 mb-16">
          <Slider {...settings}>
            {chains.map((item) => {
              return (
                <div className="mx-auto">
                  <a
                    href="#"
                    className="flex items-center w-fit rounded-full border border-primary mx-auto text-xl py-2 pl-5 pr-8"
                  >
                    <img src={item.icon} />
                    <span className="block ml-3 text-xl font-bold">
                      {item.title}
                    </span>
                  </a>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </section>
  );
}
