import { Outlet } from 'react-router-dom';
import { HomeNav } from './Navbars/HomeNav';

export default function HomeLayout() {
  return (
    <div>
      <div className="pt-10 text-white">
        <HomeNav />
      </div>

      <Outlet />
    </div>
  );
}
