import { TokenBtn } from '../form/Button';
import './style.css';

const Tokenomics = () => {
  const TokenomicsData = [
    {
      name: 'Seed Round Investors',
      supply: 5,
    },
    {
      name: 'Private Sale',
      supply: 12,
    },
    {
      name: 'Public Sale',
      supply: 2,
    },
    {
      name: 'KOLs',
      supply: 2,
    },
    {
      name: 'Liquidity',
      supply: 10,
    },
    {
      name: 'Advisory',
      supply: 3,
    },
    {
      name: 'Team',
      supply: 20,
    },
    {
      name: 'Early Incentives/ Airdrop',
      supply: 8,
    },
    {
      name: 'Ecosystem',
      supply: 20,
    },
    {
      name: 'Development/ Treasuries',
      supply: 18,
    },
  ];

  return (
    <section className="tokenomics-container xl:h-[160vh] md:h-[1000px] h-[1200px]  w-full relative overflow-hidden sm:border-b-0 border-b-2 border-primary">
      <div className="t-container lg:h-[60vw] lg:w-[60vw] sm:h-[80vw] sm:w-[80vw] h-[140vw] w-[140vw]  absolute  transform lg:bottom-24   bottom-0 lg:translate-y-0  translate-y-[50%]  translate-x-[-50%] rounded-full">
        <div className="t-line rounded-full h-full w-full p-3 pr-5">
          <div className="t-items h-full w-full rounded-full transform rotate-[135deg]"></div>
        </div>
      </div>

      <div className="container relative  h-full w-full mx-auto border border-transparent">
        <h1 className="text-white 2xl:text-7xl sm:text-5xl text-3xl font-bold sm:mt-32 mt-28 sm:ml-20 sm:text-left text-center w-full ml-0 uppercase">
          tokenomics
        </h1>

        <div className="mx-auto p-2  absolute 2xl:right-72 xl:right-48 lg:right-24 md:right-20 sm:right-0 right-1/2 sm:translate-x-0 translate-x-[50%] sm:mt-20  sm:top-1/2 transform sm:translate-y-[-50%] mt-10">
          <ul className="list-none sm:w-[400px] sm:px-0 px-5 w-full xl:h-[700px] md:h-[640px] h-[560px] flex flex-col justify-around sm:items-end items-center">
            {TokenomicsData.map((data) => {
              return (
                <li className="relative">
                  <div className="flex justify-between w-full h-full absolute">
                    <p className="absolute top-4 left-9 sm:text-base text-[12px]">
                      {data.name}
                    </p>
                    <div className="flex flex-col absolute right-8 top-[10px]">
                      <p className="text-[10px]">Supply</p>
                      <p className="sm:text-base text-[12px]">{data.supply}%</p>
                    </div>
                  </div>
                  <TokenBtn className="md:w-full w-[280px] md:h-full h-[50px]" />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Tokenomics;
