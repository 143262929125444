import { RiLinkedinBoxFill } from 'react-icons/ri';
import { RiDiscordFill } from 'react-icons/ri';
import { ImTwitter } from 'react-icons/im';
import { BiLogoTelegram } from 'react-icons/bi';
import { FaInstagramSquare } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export default function Footer() {
  const Ecosystem = [
    { title: 'TRADE', url: 'https://swap.blazpay.com/', isExternal: true },
    { title: 'EARN', url: 'https://taiko.blazpay.com/', isExternal: true },
    { title: 'GAME', url: '/comingsoon' },
    { title: 'NFT', url: '/entry-pass' },
    { title: 'TOKENOMICS', url: '/tokenomics' },
    // { title: "LITEPAPER", url: "" },
    // { title: "MERCHANDISE", url: "" },
  ];

  const Developers = [
    { title: 'CONTRIBUTING', url: 'comingsoon' },
    { title: 'GITHUB', url: 'https://github.com/blazpay', isExternal: true },
    { title: 'BUG BOUNTY', url: 'comingsoon' },
  ];

  const Support = [
    { title: 'CONTACT', url: 'comingsoon' },
    { title: 'PRIVACY', url: 'privacy' },
  ];

  const About = [
    { title: 'TERMS OF SERVICES', url: 'comingsoon' },
    { title: 'BLOG', url: 'https://medium.com/@blazpay', isExternal: true },
    {
      title: 'BRAND ASSETS',
      url: 'https://linktr.ee/Blazpay',
      isExternal: true,
    },
    { title: 'CAREERS', url: 'comingsoon' },
  ];

  return (
    <div className='w-full footer-container lg:pb-0 pb-64'>
      <hr className='border-px border-primary my-24' />
      <div className='container w-full mx-auto flex lg:flex-row flex-col lg:items-start items-center justify-between lg:px-10 font-bold relative top-0 left-0'>
        {/* Ecosystem */}
        <div className='lg:pt-0 pt-8'>
          <h1 className='text-[#ff3503] xl:text-3xl text-2xl uppercase xl:py-7 py-4 lg:text-left text-center'>
            Ecosystem
          </h1>
          <ul className='flex flex-col lg:items-start items-center text-white uppercase list-none'>
            {Ecosystem.map((item) => (
              <li
                key={item.title}
                className='xl:py-2 py-1 xl:text-xl text-base'
              >
                <Link to={item.url} target={item.isExternal ? '_blank' : ''}>
                  {item.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        {/* About */}
        <div className='lg:pt-0 pt-8'>
          <h1 className='text-[#ff3503] xl:text-3xl text-2xl uppercase xl:py-7 py-4 lg:text-left text-center'>
            About
          </h1>
          <ul className='flex flex-col lg:items-start items-center text-white uppercase list-none'>
            {About.map((item) => {
              return (
                <li className='xl:py-2 py-1 xl:text-xl text-base'>
                  <Link to={item.url} target={item.isExternal ? '_blank' : ''}>
                    {item.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        {/* Developers */}
        <div className='lg:pt-0 pt-8'>
          <h1 className='text-[#ff3503] xl:text-3xl text-2xl uppercase xl:py-7 py-4 lg:text-left text-center'>
            Developers
          </h1>
          <ul className='flex flex-col lg:items-start items-center text-white uppercase list-none'>
            {Developers.map((item) => {
              return (
                <li
                  key={item.title}
                  className='xl:py-2 py-1 xl:text-xl text-base'
                >
                  <Link to={item.url} target={item.isExternal ? '_blank' : ''}>
                    {item.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        {/* Support */}
        <div className='lg:pt-0 pt-8'>
          <h1 className='text-[#ff3503] xl:text-3xl text-2xl uppercase xl:py-7 py-4 lg:text-left text-center'>
            Support
          </h1>
          <ul className='flex flex-col lg:items-start items-center text-white uppercase list-none'>
            {Support.map((item) => {
              return (
                <li
                  key={item.title}
                  className='xl:py-2 py-1 xl:text-xl text-base'
                >
                  <Link to={item.url} target={item.isExternal ? '_blank' : ''}>
                    {item.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        {/* Social icons */}
        <div className='flex flex-col items-center absolute lg:right-0 lg:-bottom-32 -bottom-56 sm:w-[500px] w-full sm:px-0 px-8 h-fit'>
          <img src='/assets/blazpayfooter.png' alt='img' />
          <ul className='w-full flex justify-evenly list-none text-[#ff4700] h-16'>
            <li>
              <a href='https://twitter.com/blazpaylabs' target='_blank'>
                <ImTwitter className='h-8 w-8 hover:text-primary hover:-translate-y-1 ease-in-out transition-all hover:scale-110 duration-250' />
              </a>
            </li>
            <li>
              <a
                href='https://www.linkedin.com/company/blazpay'
                target='_blank'
              >
                <RiLinkedinBoxFill className='h-8 w-8 hover:text-primary hover:-translate-y-1 ease-in-out transition-all hover:scale-110 duration-250' />
              </a>
            </li>
            <li>
              <a href='https://discord.com/invite/BlazPay' target='_blank'>
                <RiDiscordFill className='h-8 w-8 hover:text-primary hover:-translate-y-1 ease-in-out transition-all hover:scale-110 duration-250' />
              </a>
            </li>
            <li>
              <a href='https://t.me/Blazpay' target='_blank'>
                <BiLogoTelegram className='h-8 w-8 hover:text-primary hover:-translate-y-1 ease-in-out transition-all hover:scale-110 duration-250' />
              </a>
            </li>
            <li>
              <a href='https://instagram.com/Blazpay' target='_blank'>
                <FaInstagramSquare className='h-8 w-8 hover:text-primary hover:-translate-y-1 ease-in-out transition-all hover:scale-110 duration-250' />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
