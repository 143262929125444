import { useNavigate } from "react-router-dom";
import "./hero.css";
export default function Hero() {
  const navigate = useNavigate();
  const handleClaimNowClick = () => {
    navigate("/entry-pass");
  };
  return (
    <section className="w-full hero-gradient -mt-12">
      <div className="wrapper hero-gradient">
        {/*  First page of Hero Section  */}
        <div className="first-page">
          <div className="text-section">
            <h1>
              <span>G</span>
              <span>R</span>
              <span>A</span>
              <span>B</span>
              <span>Y</span>
              <span>O</span>
              <span>U</span>
              <span>R</span>
              <p>
                <span>E</span>
                <span>N</span>
                <span>T</span>
                <span>R</span>
                <span>Y</span>
                <span>P</span>
                <span>A</span>
                <span>S</span>
                <span>S</span>
              </p>
            </h1>
            <h2>gain exclusive access to the blazpay dashboard</h2>
            <ul>
              <li>Secure your EntryPass now to unlock the Blazpay Dashboard</li>
              <li>
                Accumulate Blazpoints and participate in the BlazPay Airdrop
              </li>
            </ul>
            <div className="btn-container">
              <button onClick={handleClaimNowClick}>Claim Now</button>
            </div>
          </div>
          <div className="animation-section">
            <div className="card">
              <img
                src="/assets/hero/herocard.png"
                className="card-img"
                alt=""
              />
              <video src="/assets/hero/vd.mp4" autoPlay muted loop></video>
              <div className="card-blink">
                <img src="/assets/hero/blazpayicon.png" alt="" />
                <h1>X</h1>
                <p>taiko</p>
              </div>
            </div>
            <div className="card">
              <img
                src="/assets/hero/herocard.png"
                className="card-img"
                alt=""
              />
              <video src="/assets/hero/vd.mp4" autoPlay muted loop></video>
              <div className="card-blink">
                <img src="/assets/hero/blazpayicon.png" alt="" />

                <h1>X</h1>
                <p>taiko</p>
              </div>
            </div>
          </div>
        </div>
        {/*  First page of hero section end  */}

        {/*  Section page of Hero section  */}
        <div className="lt-wrapper">
          <div className="animated-circles">
            <div className="circle"></div>
          </div>
          <div className="lt-text-container">
            <h1>
              <span>Y</span>
              <span>O</span>
              <span>U</span>
              <span>R</span>
              <span>&nbsp;</span>
              <span>A</span>
              <span>L</span>
              <span>L</span>
              <span>-</span>
              <span>I</span>
              <span>N</span>
              <span>-</span>
              <span>O</span>
              <span>N</span>
              <span>E</span>
              <span>&nbsp;</span>
              <span>C</span>
              <span>R</span>
              <span>Y</span>
              <span>P</span>
              <span>T</span>
              <span>O</span>
              <span>&nbsp;</span>
              <span>H</span>
              <span>U</span>
              <span>B</span>
            </h1>
            <p>
              Experience the convenience of trading, swapping, managing and
              earning all from the Blazpay Dashboard
            </p>
          </div>
          <div className="laptop-container">
            <div className="lt-top">
              <div className="lt-top-child"></div>
            </div>
            <div className="lt-bottom"></div>
          </div>
        </div>
        {/*  Second page of hero section end   */}

        {/* Third page of hero section  */}
        <div className="mobile-app">
          <img src="/assets/blazpaymobile.png" alt="" />
          <h1>
            <span>S</span>
            <span>U</span>
            <span>P</span>
            <span>E</span>
            <span>R</span>
            <span>A</span>
            <span>P</span>
            <span>P</span>
          </h1>
          <h2>Your Ultimate Crypto Financial Toolkit within one app</h2>
          <p>
            Simplify your crypto transactions with one comprehensive mobile app
          </p>
          <div className="btn-container">
            <button>Claim Now</button>
          </div>
        </div>
        {/* Third page of hero section end  */}

        {/* Fourth page of hero section motion */}

        <div className="mobile-cards">
          <div className="mobile">
            <img src="/assets/hero/slides/m1.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m2.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m3.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m1.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m4.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m5.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m6.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m7.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m8.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m9.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m10.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m11.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m12.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m13.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m14.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m15.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m16.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m17.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m18.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m19.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m20.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m21.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m22.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m23.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m24.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m25.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m26.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m27.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m28.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m29.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m30.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m31.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m32.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m33.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m34.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m35.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m36.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m37.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m38.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m39.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m40.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m41.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m42.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m43.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m44.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m45.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m46.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m47.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m48.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m49.png" alt="" />
          </div>
          <div className="mobile">
            <img src="/assets/hero/slides/m50.png" alt="" />
          </div>
        </div>

        {/* Fourth page of hero section motion end */}
      </div>
    </section>
  );
}
