import React from "react";
import "./style.css";
const About = () => {
  return (
    <div className="min-h-screen w-full aboutus-container flex justify-center items-center -mt-10">
      <div
        style={{ perspective: "5000px" }}
        className="container mx-auto h-full w-full  flex lg:flex-row flex-col justify-between items-center text-white lg:px-20 px-5 lg:pb-0 pb-10 md:text-left text-center"
      >
        <div className="flex flex-col lg:w-3/5 w-full xl:-mt-20 mt-28 xl:pb-0 pb-10">
          <h1 className="md:text-5xl text-4xl scaleText">About us</h1>
          <p className="lg:text-2xl md:text-2xl text-xl sm:mt-12 mt-8">
            At Blazpay, we're dedicated to innovating in the field of
            crypto-financial solutions with a comprehensive suite of products.
            Our vision is to offer a seamless, one-stop experience for users
            navigating the complex world of cryptocurrency. Our flagship
            product, the DeFi Super App,redefines the landscape by enabling
            hassle-free payments using domains, providing an On-Ramp/Off-Ramp
            solution, acting as a Dex aggregator,facilitating AI trading,
            connecting DApps, and offering Chat & Pay among many other features,
            thus ensuring a user-friendly and efficient transaction process.
          </p>
        </div>
        <img
          src="/assets/aboutUs.png"
          alt=""
          className="lg:w-2/4 md:w-3/5 w-4/5 xl:mt-0 lg:mt-40 about-img"
          style={{ filter: "drop-shadow(0 0 50px red)" }}
        />
      </div>
    </div>
  );
};

export default About;
