import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react';
import { chains } from './chains';

const projectId = 'cf0545f2b1063ac84b6804ee8995cc74';

const chainList = chains.map((chain) => ({
  chainId: +chain.id,
  name: chain.name,
  currency: chain.nativeCurrency.symbol,
  explorerUrl: chain.explorerUrl || null,
  rpcUrl: chain.rpcUrls[0],
}));

const metadata = {
  name: 'Blazpay',
  description: 'Blazpay',
  url: 'https://blazpay.com',
  icons: ['https://blazpay.com/blazpoint.png'],
};

const ethersConfig = defaultConfig({
  metadata,
  enableEIP6963: true,
  enableInjected: true,
  enableCoinbase: true,
  rpcUrl: '...', // Provide your RPC URL here
  defaultChainId: chainList[0].chainId,
});

const web3Modal = createWeb3Modal({
  ethersConfig,
  defaultChain: chainList[0],
  chains: chainList,
  projectId,
  enableAnalytics: true,
  allowUnsupportedChain: true,
});

export default web3Modal;
